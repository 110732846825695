import AbstractService from 'services/AbstractService';
import UserDocumentModel from 'services/models/UserDocumentModel';
import UserDocumentDataModel from 'services/models/UserDocumentDataModel';
import Auth from 'modules/Auth';

class UserServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'users/';
    this.passwordEndpoint = '/account/resetpassword/verify/';
    //this.getUserSuggestions = this.getUserSuggestions.bind(this, '.*');
  }

  /**
   * Create a user.
   */
  create(data) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, data, config);
  }

  /**
   * Find user by email or id
   * @param {string} userIdOrEmail
   */
  getUser(userIdOrEmail) {
    var endpoint = this.endpoint + userIdOrEmail;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getPendingUser(userId) {
    var endpoint = this.endpoint + 'getPendingUser/' + userId;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getUserByPhone(phone) {
    var endpoint = this.endpoint + 'byPhone/' + phone;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getUserByName(name) {
    var endpoint = this.endpoint + 'byName/' + name;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  updateUserWithData(userId, data) {
    const endpoint = this.endpoint + userId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, data, config);
  }

  updateVisitorPhone(userId, phone) {
    const endpoint = this.endpoint + userId + '/phone';

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, { phone }, config);
  }

  /**
   * Update user photo
   * @param {string} userId
   */
  updateUserPhoto(userId, photo) {
    let data = {
      photo: photo,
    };

    return this.updateUserWithData(userId, data);
  }

  updateUserNotifications(userId, enabledNotifications) {
    let data = {
      enabledNotifications: enabledNotifications,
    };

    return this.updateUserWithData(userId, data);
  }

  updateUserAssistant(userId, assistant) {
    let data = {
      assistant: assistant,
    };

    return this.updateUserWithData(userId, data);
  }

  updateUserDeputy(userId, deputy) {
    let data = {
      deputy: deputy,
    };

    return this.updateUserWithData(userId, data);
  }

  /**
   * Adds device token to currently logged user
   */
  updateCurrentUserWithPushToken(token) {
    const currentUser = Auth.getUser();
    const userId = currentUser._id;
    if (!userId) {
      return;
    }

    this.getUser(userId).then((userData) => {
      // get current user tokens
      let tokens = userData.pushTokens ? userData.pushTokens.slice() : [];
      // if user has already registered this token.. do nothing
      if (tokens.find((t) => t === token)) {
        return;
      }
      // else add token to tokens and make update request
      tokens.push(token);
      let data = {
        pushTokens: tokens,
      };

      return this.updateUserWithData(userId, data);
    });
  }

  /**
   * Delete user by email or id
   * @param {string} userId
   */
  deleteUser(userId) {
    var endpoint = 'users/' + userId;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  /**
   * Get all users
   */
  getUsers() {
    var endpoint = 'users/';
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getUserActivationToken(user_id) {
    var endpoint = 'users/activate/token/' + user_id;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getUserActivationUrl(user_id, activationToken) {
    let location = window.location;
    let host =
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '');
    return (
      host +
      '/account/activate/' +
      user_id +
      '?token=' +
      encodeURIComponent(activationToken)
    );
  }

  /**
   * Find all users whose name contains provided search value.
   *
   * @param {string} searchValue String that should be contained in user name
   * @param {boolean} host Boolean is true if only employee and admin role users should be returned
   */
  getUserSuggestions = (
    searchValue,
    employeeOnly = false,
    inviteOnly = false,
    meeting = false,
    fields
  ) => {
    const field = fields ? fields.join(',') : 'name';
    var endpoint =
      'users/suggestions/' +
      searchValue +
      '?field=' +
      field +
      '&employee=' +
      (employeeOnly ? 1 : 0) +
      '&invite=' +
      (inviteOnly ? 1 : 0) +
      '&meeting=' +
      (meeting ? 1 : 0);
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  /**
   * Find all users whose email contains provided search value.
   *
   * @param {string} searchValue String that should be contained in user email
   * @param {boolean} host Boolean is treu if only employee and admin role users should be returned
   */
  getUserEmailSuggestions = (
    searchValue,
    employeeOnly = false,
    inviteOnly = false
  ) => {
    var endpoint =
      'users/suggestions/' +
      searchValue +
      '?field=email&employee=' +
      (employeeOnly ? 1 : 0) +
      '&invite=' +
      (inviteOnly ? 1 : 0);
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  getUserPhoneSuggestions = (
    searchValue,
    employeeOnly = false,
    inviteOnly = false
  ) => {
    var endpoint =
      'users/suggestions/' +
      searchValue +
      '?field=phone&employee=' +
      (employeeOnly ? 1 : 0) +
      '&invite=' +
      (inviteOnly ? 1 : 0);
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  /**
   * Find all host user whose name contains provided search value.
   *
   * @param {string} searchValue String that should be contained in user name
   */
  getHostSuggestions = (searchValue) => {
    return this.getUserSuggestions(searchValue, true);
  };

  getUserDocument = (documentId) => {
    const endpoint = 'users/documents/' + documentId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  /**
   * Send answered safety trainings questions
   */
  sendQuestionnaireAnswers(user_document_id, status, data) {
    return this.updateDocument(
      undefined,
      user_document_id,
      'questionnaire',
      status,
      data
    );
  }

  sendFeedbackAnswers(user_document_id, status, data) {
    return this.updateDocument(
      undefined,
      user_document_id,
      'feedback',
      status,
      data
    );
  }

  signUserDocument(user_document_id, filled_template, email_data) {
    const endpoint = 'users/documents/sign/' + user_document_id;
    const data = {
      filled_template: filled_template,
      email_data: email_data,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, data, config);
  }

  /**
   * Update user document
   */
  updateDocument(user_id, document_id, document_type, status, data) {
    const endpoint = 'users/documents/' + document_id;
    const userDocument = new UserDocumentModel(
      null,
      user_id,
      document_type,
      status,
      data
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, userDocument.toJSON(), config);
  }

  updateDocumentStatus(document_id, status) {
    const endpoint = 'users/documents/' + document_id;
    var userDocument = { status: status };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.put(endpoint, userDocument, config);
  }

  /**
   * Add document to user, use this for assigning also questionnaires, for questionnaires answers use sendQuestionnaireAnswers
   */
  addDocumentWithBaseData(
    user_id,
    document_type,
    status,
    valid_until,
    document_name,
    document_id
  ) {
    const data = new UserDocumentDataModel(document_name, document_id);
    return this.addDocument(user_id, document_type, status, valid_until, data);
  }

  /**
   * Add document to user
   */
  addDocument(user_id, document_type, status, data) {
    const endpoint = 'users/documents';
    const userDocument = new UserDocumentModel(
      null,
      user_id,
      document_type,
      status,
      data
    );
    var bodyFormData = new FormData();
    bodyFormData.append('document', data.data);
    bodyFormData.append('data', userDocument.toJSON());

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(endpoint, bodyFormData, config);
  }

  /**
   * Get documents assigned to user (nda, trainings, questionnaire)
   */
  getUserDocuments(user_id) {
    const endpoint = 'users/' + user_id + '/documents';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getNonPersonalDocuments(user_id) {
    const endpoint = 'users/' + user_id + '/without-personal-documents';
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getDocumentsByType(user_id, type) {
    const endpoint = 'users/' + user_id + '/documents/type' + type;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Send answered safety trainings questions
   */
  removeDocument(documentId) {
    const endpoint = 'users/documents/' + documentId;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  /**
   * Verify if user can be activated.
   *
   * @param {string} userId User ID
   * @param {string} activationToken Activation token
   */
  verifyUserActivation(userId, activationToken) {
    var endpoint = 'users/activate/verify/' + userId;
    var body = {
      token: activationToken,
    };

    return this.post(endpoint, body);
  }

  /**
   * Verify if user can be activated.
   *
   * @param {string} userId User ID
   * @param {string} activationToken Activation token
   */
  activateUserAccount(userId, activationToken, password, agreeTerms) {
    var endpoint = 'users/activate/' + userId;
    var body = {
      token: activationToken,
      password: password,
      agreeTerms: agreeTerms,
    };

    return this.post(endpoint, body);
  }

  /**
   * Request reset password instructions
   *
   * @param {string} email Email of user
   */
  requestResetPassword(email) {
    var endpoint = 'users/resetpassword/request/';
    var body = {
      email: email,
    };

    return this.post(endpoint, body);
  }

  /**
   * Verify reset password using provided JWT
   *
   * @param {string} reset_token JWT from email
   */
  verifyResetPassword(reset_token) {
    var endpoint = 'users/resetpassword/verify/';
    var body = {
      reset_token: reset_token,
    };

    return this.post(endpoint, body);
  }

  /**
   *  Reset password using provided JWT
   *
   * @param {string} reset_token JWT from email
   * @param {string} password New password
   */
  resetPassword(reset_token, password) {
    var endpoint = 'users/resetpassword/reset/';
    var body = {
      reset_token: reset_token,
      password: password,
    };

    return this.post(endpoint, body);
  }

  /**
   * Update password.
   *
   * @param {string} userId User ID
   * @param {string} oldPassword Old password
   * @param {string} newPassword New password
   */
  changePassword(userId, oldPassword, newPassword) {
    var endpoint = 'users/changepassword/' + userId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    var body = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    return this.post(endpoint, body, config);
  }

  changePasswordAsAdmin(userId, password) {
    var endpoint = 'users/changepasswordadmin/' + userId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    var body = {
      password: password,
    };

    return this.post(endpoint, body, config);
  }

  changePin(userId, oldPin, newPin) {
    const endpoint = 'users/changepin/' + userId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    const body = {
      old_pin: oldPin,
      new_pin: newPin,
    };

    return this.post(endpoint, body, config);
  }

  changePinAsAdmin(userId, pin) {
    const endpoint = 'users/changepinadmin/' + userId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    const body = {
      pin: pin,
    };

    return this.post(endpoint, body, config);
  }

  importUsers(users, role) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(this.endpoint, { users, role }, config);
  }

  getUsersTenants(userId) {
    var endpoint = 'users/' + userId + '/tenants';

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  checkEmailUsed(email, ignoreUser) {
    const endpoint = `${this.endpoint}checkEmailUsed/${email}${
      ignoreUser ? `?ignore=${ignoreUser}` : ''
    }`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers,
    };

    return this.get(endpoint, config);
  }

  checkPhoneUsed(phone) {
    const endpoint = `${this.endpoint}checkPhoneUsed/${phone}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers,
    };
    return this.get(endpoint, config);
  }

  tabletEmployeeLogin(data) {
    const endpoint = `${this.endpoint}tabletEmployeeLogin`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers,
    };

    return this.post(endpoint, data, config);
  }

  getSecurityJWT(data) {
    const endpoint = `${this.endpoint}getSecurityJWT`;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers,
    };
    return this.post(endpoint, data, config);
  }
}

/**
 * Global function used by device webview to register device token for push notifications.
 */
window.addDeviceToken = (token) => {
  UserService.updateCurrentUserWithPushToken(token);
};

const UserService = new UserServiceImpl();

export default UserService;
